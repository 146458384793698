<template>
  <div class="content-provider">
    <div class="content-tables-provider">
      <div class="content-global">
        <div ref="searchbarGlobal">
          <header-component-global sTextTitle="Proveedores" sTextBtnAdd="Añadir proveedor +"
            :bAdminPermissions="bAdminProvider" :toVieworModal="true" setDialog="AddProvider" />
        </div>
        <div ref="tabGlobal">
          <v-tabs v-model="tab" class="global-tabs tabs-provider" :touchless="true" center-active
            background-color="transparent">
            <v-tab class="global-tab" v-for="item in tabsItems" :key="item">
              {{ item }}
            </v-tab>
            <v-tabs-slider class="divider-tabs"></v-tabs-slider>
          </v-tabs>
        </div>
        <div ref="filterGlobal">
          <filter-component-global class="global-filters" @setSearch="setSearch" :labelName="searchLabel"
            :placeholderName="searchPlaceholder" />
        </div>
        <v-tabs-items v-model="tab" class="global-item-tab">
          <!-- tab de empresas -->
          <v-tab-item>
            <div dark class="global-content-table">
              <no-data-text-component-global :iHeight="screenHeight" :sTextEmpty="sTextEmpty" v-if="bEmptyTable"
                :bAdminPermissions="bAdminProvider" :toVieworModal="true" setDialog="AddProvider" />
              <v-data-table v-else v-show="!isMobile" mobile-breakpoint="0" :headers="headers" :disable-sort="true"
                :items="aTable" :loading="bLoadingTable" loading-text="Cargando datos..."
                :items-per-page="iItemsPerPage" :hide-default-footer="true"
                no-data-text="No hay información disponible para mostrar." :footer-props="{
                  'items-per-page-text': 'Filas por página',
                  'items-per-page-all-text': 'Todos',
                }" class="global-table">
                <template slot="progress">
                  <v-progress-linear color="var(--primary-color-loading-table)" indeterminate></v-progress-linear>
                </template>

                <template v-slot:[`item.accions`]="{ item }">
                  <v-btn @click="detailProvider(item)" class="global-btn-table-detail" icon>
                    <v-icon> mdi-eye-outline </v-icon>
                  </v-btn>
                  <v-btn v-show="bAdminProvider" @click="deleteItem(item)" class="global-btn-table-delete" icon>
                    <v-icon> mdi-trash-can-outline </v-icon>
                  </v-btn>
                </template>
                <template v-slot:footer>
                  <footer-table-component-global :numPages="numPages" :totalItems="totalItems"
                    :bChangeSelectedItem="bChangeSelectedItem" @changeTable="getEmitEnterprises" />
                </template>
              </v-data-table>
              <table-mobile-provider-component v-show="isMobile" @changeTable="getEmitEnterprises"
                @openDetailItem="detailProvider" @openDeleteItem="deleteItem" :aTable="aTable" :headers="headers"
                :numPages="numPages" :totalItems="totalItems" :bChangeSelectedItem="bChangeSelectedItem" />
            </div>
          </v-tab-item>
          <!-- tab de personas -->
          <v-tab-item>
            <div dark class="global-content-table">
              <no-data-text-component-global :sTextEmpty="sTextEmpty" :iHeight="screenHeight" v-if="bEmptyTable"
                :bAdminPermissions="bAdminProvider" :toVieworModal="true" setDialog="AddProvider" />
              <v-data-table v-else v-show="!isMobile" mobile-breakpoint="0" :headers="headers" :disable-sort="true"
                :items="aTable" :loading="bLoadingTable" loading-text="Cargando datos..."
                :items-per-page="iItemsPerPage" :hide-default-footer="true"
                no-data-text="No hay información disponible para mostrar." :footer-props="{
                  'items-per-page-text': 'Filas por página',
                  'items-per-page-all-text': 'Todos',
                }" class="global-table">
                <template slot="progress">
                  <v-progress-linear color="var(--primary-color-loading-table)" indeterminate></v-progress-linear>
                </template>
                <template v-slot:[`item.sEmail`]="{ item }">
                  <span :class="
                    item.sEmail == 'Sin información.' ? 'text-data-empty' : ''
                  ">{{ item.sEmail }}</span>
                </template>
                <template v-slot:[`item.sPhone`]="{ item }">
                  <span :class="
                    item.sPhone == 'Sin información.' ? 'text-data-empty' : ''
                  ">{{ item.sPhone }}</span>
                </template>
                <template v-slot:[`item.sLocation`]="{ item }">
                  <span :class="
                    item.sLocation == 'Sin información.'
                      ? 'text-data-empty'
                      : ''
                  ">{{ item.sLocation }}</span>
                </template>
                <template v-slot:[`item.accions`]="{ item }">
                  <v-btn @click="detailProvider(item)" class="global-btn-table-detail" icon>
                    <v-icon> mdi-eye-outline </v-icon>
                  </v-btn>
                  <v-btn v-show="bAdminProvider" @click="deleteItem(item)" class="global-btn-table-delete" icon>
                    <v-icon> mdi-trash-can-outline </v-icon>
                  </v-btn>
                </template>
                <template v-slot:footer>
                  <footer-table-component-global :numPages="numPages" :totalItems="totalItems"
                    :bChangeSelectedItem="bChangeSelectedItem" @changeTable="gerEmitPerson" />
                </template>
              </v-data-table>
              <table-mobile-person-component v-show="isMobile" @changeTable="gerEmitPerson"
                @openDetailItem="detailProvider" @openDeleteItem="deleteItem" :aTable="aTable" :headers="headers"
                :numPages="numPages" :totalItems="totalItems" :bChangeSelectedItem="bChangeSelectedItem" />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <!-- <footer-component-global /> -->
    </div>
    <delete-component-global @setDialogDelete="deleteItem" :oDialogDelete="oDialogDelete"
      :bDialogDelete="bDialogDelete" />
  </div>
</template>

<script>
export default {
  name: "Provider",
  data() {
    return {
      tab: 0,
      activeTab: 0,
      bChangeSelectedItem: false,
      emptyData: "Sin información.",
      searchLabel: "Buscador de proveedor tipo empresa",
      searchPlaceholder: "Ingrese nombre, correo electrónico o teléfono...",
      sSearch: "",
      screenHeight: 0,
      heightMax: 0,
      screenWidth: 0,
      bLoadingTable: true,
      bEmptyTable: false,
      iCurrentPage: 1,
      iPageNumber: 1,
      iItemsPerPage: 30,
      numPages: 0,
      totalItems: 0,
      totalView: 0,
      tabsItems: {
        enterprice: "Empresas",
        persons: "Personas físicas",
      },
      sTextEmpty: "",

      headers: [
        {
          text: "Nombre de la empresa",
          sortable: false,
          value: "sName",
          class: "global-header-table global-header-name",
          cellClass: "global-body-table global-body-name ",
        },
        {
          text: "Nombre del encargado",
          sortable: false,
          value: "sNameManager",
          class: "global-header-table global-header-name",
          cellClass: "global-body-table global-body-name ",
        },
        {
          text: "Teléfono del encargado",
          value: "sPhone",
          class: "global-header-table global-header-phone",
          cellClass: "global-body-table",
        },
        {
          text: "Correo del encargado",
          sortable: false,
          value: "sEmail",
          class: "global-header-table global-header-email",
          cellClass: "global-body-table",
        },
        {
          text: "Ubicación﻿",
          value: "sLocation",
          class: "global-header-table global-header-location",
          cellClass: "global-body-table",
        },
        {
          text: "Acciones",
          value: "accions",
          align: "center",
          class:
            "global-header-table global-header-accions global-col-header-accion",
          cellClass:
            "global-body-table global-body-accions global-col-body-accion",
        },
      ],
      aTable: [],
      isMobile: false,
      bDialogDelete: false,
      oDialogDelete: {
        active: false,
        textTitle: "",
        textDescription: "",
        textQuestion: "",
        api: "",
        returnToView: false,
      },
    };
  },
  beforeMount() {
    this.setTextEmpty();

    // this.getEnterprises();
    this.tab = this.sTabPosition;
    this.sTabPosition === 0 ? this.getEnterprises() : this.getPerson();
  },
  mounted() {
    // setup click event for next icon
    this.clickNextTab();

    // setup click event for previous icon
    this.clickPrevTab();
  },
  updated() {
    this.matchHeight();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    window.addEventListener("resize ", this.matchHeight);
    this.matchHeight();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    window.addEventListener("resize ", this.matchHeight);
  },
  methods: {
    //#region RESIZE PAGE
    matchHeight() {
      this.$nextTick((e) => {
        if (this.$refs.searchbarGlobal !== undefined) {
          this.heightMax =
            this.$refs.searchbarGlobal.clientHeight +
            this.$refs.tabGlobal.clientHeight +
            this.$refs.filterGlobal.clientHeight;
          this.heightMax = this.heightMax + 90; //123 IS NAVBAR
        }
        if (window.innerWidth > 599) {
          this.isMobile = false;
          // this.screenHeight = window.innerHeight - this.heightMax;
          this.screenHeight = window.innerHeight - this.heightMax;
        } else {
          this.isMobile = true;
          // this.screenHeight = window.innerHeight - this.heightMax;
          this.screenHeight = window.innerHeight - this.heightMax;
        }
      });
    },
    handleResize: function () {
      this.matchHeight();
    },
    //#endregion RESIZE PAGE

    clickNextTab() {
      document
        .getElementsByClassName("v-slide-group__next")[0]
        .addEventListener("click", () => {
          this.tab = this.activeTab + 1;
        });
    },
    clickPrevTab() {
      document
        .getElementsByClassName("v-slide-group__prev")[0]
        .addEventListener("click", () => {
          this.tab = this.activeTab - 1;
        });
    },
    deleteItem(item) {
      if (item) {
        let sNameType = this.sTabPosition === 0 ? "empresa" : "persona física";
        let sNameTypeApi = this.sTabPosition === 0 ? "enterprises" : "person";

        this.oDialogDelete.textTitle = `Eliminar ${sNameType}`;
        this.oDialogDelete.textDescription = `La eliminación de una ${sNameType} es una acción irreversible.`;
        this.oDialogDelete.textQuestion =
          `Acepto la responsabilidad y deseo proseguir con la eliminación de la  ${sNameType}: ` +
          item.sName +
          ".";
        this.oDialogDelete.api = `${URI}/api/sp/v1/vendors/${sNameTypeApi}/${item.sId}`;
      }
      this.bDialogDelete = !this.bDialogDelete;
    },
    detailProvider(item) {
      this.$router
        .push(
          `/home/provider-detail/${this.sTabPosition === 0
            ? item.sVendorEnterpriseId
            : item.sVendorPersonId
          }`
        )
        .catch(() => { });
    },
    getEnterprises() {
      DB.get(`${URI}/api/sp/v1/vendors/enterprises`, {
        params: {
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: this.iItemsPerPage,
          sSearch: this.sSearch,
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.numPages = response.data.numPages;
          this.totalItems = response.data.total;

          this.aTable = response.data.results.map((e) => {
            return {
              ...e,
              sId: e.sVendorEnterpriseId,
              sName: e.sPublicName,
              sNameManager: e.oManagerInfo.sFullName,
              sEmail: e.oManagerInfo.sEmail,
              sPhone: this.formatPhoneProvider(e.oManagerInfo.oContactInfo),
              sLocation: this.formatLocation(e.oGeneralInfo.oLocationInfo),
            };
          });
          this.bLoadingTable = false;
          this.bEmptyTable = this.aTable.length < 1;
          this.totalView = this.aTable.length;
          this.$store
            .dispatch("getPermissionsByUserGlobal")
            .then((resp) => { })
            .catch((err) => {
              this.Error(err);
            });
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.bLoadingTable = false;
          this.Error(error.response.data);
        });
    },
    getPerson() {
      DB.get(`${URI}/api/sp/v1/vendors/person`, {
        params: {
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: this.iItemsPerPage,
          sSearch: this.sSearch,
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.numPages = response.data.numPages;
          this.totalItems = response.data.total;
          this.aTable = response.data.results.map((e) => {
            return {
              ...e,
              sId: e.sVendorPersonId,
              sName: e.sFullName,
              sEmail: this.formatEmptyEmail(e.sEmail)
                ? e.sEmail
                : this.emptyData,
              sPhone: this.formatEmptyPhone(e.oContactInfo)
                ? this.formatPhonePerson(e.oContactInfo)
                : this.emptyData,
              sLocation: this.formatEmptyLocation(e.oLocationInfo)
                ? this.formatLocation(e.oLocationInfo)
                : this.emptyData,
            };
          });
          this.bLoadingTable = false;
          this.bEmptyTable = this.aTable.length < 1;

          this.totalView = this.aTable.length;
          this.$store
            .dispatch("getPermissionsByUserGlobal")
            .then((resp) => { })
            .catch((err) => {
              this.Error(err);
            });
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.bLoadingTable = false;
          this.Error(error.response.data);
        });
    },
    getEmitEnterprises(obj) {
      this.iCurrentPage = obj.iCurrentPage;
      this.iItemsPerPage = obj.iItemsPerPage;
      this.getEnterprises();
    },
    gerEmitPerson(obj) {
      this.iCurrentPage = obj.iCurrentPage;
      this.iItemsPerPage = obj.iItemsPerPage;
      this.getPerson();
    },

    setSearch(sSearch) {
      this.sSearch = sSearch
    },

    formatPhoneProvider(oContactInfo) {
      let sCountryCallingCode = oContactInfo.sCountryCallingCode;
      switch (sCountryCallingCode) {
        case "52":
          return (
            "+" +
            oContactInfo.sCountryCallingCode +
            " (" +
            oContactInfo.sAreaCallingCode +
            ") " +
            [
              oContactInfo.sPhoneNumber.slice(0, 4),
              "-",
              oContactInfo.sPhoneNumber.slice(4),
            ].join("") +
            (oContactInfo.sExtension
              ? " ext. " + oContactInfo.sExtension + ""
              : "")
          );

        case "1":
          return (
            "+" +
            oContactInfo.sCountryCallingCode +
            " (" +
            oContactInfo.sAreaCallingCode +
            ") " +
            [
              oContactInfo.sPhoneNumber.slice(0, 3),
              "-",
              oContactInfo.sPhoneNumber.slice(3),
            ].join("") +
            (oContactInfo.sExtension
              ? " ext. " + oContactInfo.sExtension + ""
              : "")
          );

        default:
          break;
      }
    },
    formatPhonePerson(oContactInfo) {
      let sCountryCallingCode = oContactInfo.sCountryCallingCode;
      switch (sCountryCallingCode) {
        case "52":
          return (
            "+" +
            oContactInfo.sCountryCallingCode +
            " (" +
            oContactInfo.sAreaCallingCode +
            ") " +
            [
              oContactInfo.sPhoneNumber.slice(0, 4),
              "-",
              oContactInfo.sPhoneNumber.slice(4),
            ].join("") +
            (oContactInfo.sPhoneExtension
              ? " ext. " + oContactInfo.sPhoneExtension + ""
              : "")
          );

        case "1":
          return (
            "+" +
            oContactInfo.sCountryCallingCode +
            " (" +
            oContactInfo.sAreaCallingCode +
            ") " +
            [
              oContactInfo.sPhoneNumber.slice(0, 3),
              "-",
              oContactInfo.sPhoneNumber.slice(3),
            ].join("") +
            (oContactInfo.sPhoneExtension
              ? " ext. " + oContactInfo.sPhoneExtension + ""
              : "")
          );

        default:
          break;
      }
    },
    formatLocation(oLocation) {
      let sCountry =
        oLocation.oCountry.sCountryId === "236ce2ad-d8d8-41eb-8451-a0c7c6a7434b"
          ? "MX"
          : "EUA";
      let location =
        oLocation.sCityName + ", " + sCountry;
      return location;
    },
    formatEmptyEmail(item) {
      let bln = true;
      if (item === "") {
        bln = false;
      }
      return bln;
    },
    formatEmptyPhone(item) {
      let bln = true;
      if ((item.sPhoneNumber === "" || item.sPhoneNumber === null) || (item.sAreaCallingCode === "" || item.sAreaCallingCode === null)) {
        bln = false;
      }
      return bln;
    },
    formatEmptyLocation(item) {
      let bln = true;
      if (
        item.sCityName === "" ||
        item.oCountry.sName === null
      ) {
        bln = false;
      }
      return bln;
    },
    setTextEmpty() {
      let sTextemptyTemp = " <p>No existen proveedores registrados.</p>"
      this.bAdminProvider ? sTextemptyTemp += "<p v-on:click='this.setAddProvider()'>Comienza <span class='text-information-link'>registrando uno.</span> </p>" : ""
      this.sTextEmpty = sTextemptyTemp;
    },

    // FUNCTION OF FOOTER TABLE
    changeTable(e) {
      this.iItemsPerPage = e;
      this.iCurrentPage = 1;
      if (this.sTabPosition === 0) {
        this.getEnterprises();
      } else {
        this.getPerson();
      }
    },
    backPage() {
      if (this.iCurrentPage > 1) {
        this.iCurrentPage--;
      }
    },
    nextPage() {
      if (this.iCurrentPage < this.numPages) {
        this.iCurrentPage++;
      }
    },
    firstPage() {
      this.iCurrentPage = 1;
    },
    lastPage() {
      this.iCurrentPage = this.numPages;
    },
  },

  computed: {
    sTabPosition() {
      return this.$store.state.sTabPosition;
    },
    refreshTable() {
      this.iCurrentPage = 1;
      return this.$store.state.refresh;
    },
    bShowProvider() {
      return this.$store.state.bShowProvider;
    },
    bAdminProvider() {
      return this.$store.state.bAdminProvider;
    },
  },
  watch: {
    tab() {
      this.iCurrentPage = 1;
      this.iPageNumber = 1;
      this.iItemsPerPage = 30;
      this.numPages = 0;
      this.totalItems = 0;
      this.totalView = 0;
      this.aTable = [];
      this.bLoadingTable = true;
      this.activeTab = this.tab;
      this.bChangeSelectedItem = !this.bChangeSelectedItem;
      this.headers = [];
      if (this.tab == 0) {
        this.bEmptyTable = false;
        this.headers = [
          {
            text: "Nombre de la empresa",
            sortable: false,
            value: "sName",
            class: "global-header-table global-header-name",
            cellClass: "global-body-table global-body-name ",
          },
          {
            text: "Nombre del encargado",
            sortable: false,
            value: "sNameManager",
            class: "global-header-table global-header-name",
            cellClass: "global-body-table global-body-name ",
          },
          {
            text: "Teléfono del encargado",
            value: "sPhone",
            class: "global-header-table global-header-phone",
            cellClass: "global-body-table",
          },
          {
            text: "Correo del encargado",
            sortable: false,
            value: "sEmail",
            class: "global-header-table global-header-email",
            cellClass: "global-body-table",
          },
          {
            text: "Ubicación﻿",
            value: "sLocation",
            class: "global-header-table global-header-location",
            cellClass: "global-body-table",
          },
          {
            text: "Acciones",
            value: "accions",
            align: "center",
            class:
              "global-header-table global-header-accions global-col-header-accion",
            cellClass:
              "global-body-table global-body-accions global-col-body-accion",
          },
        ]

        this.getEnterprises();
        this.searchLabel = "Buscador de proveedor tipo empresa";
        this.searchPlaceholder =
          "Ingrese nombre, correo electrónico o teléfono...";
      } else {
        this.bEmptyTable = false;
        this.headers = [
          {
            text: "Nombre de la persona",
            sortable: false,
            value: "sName",
            class: "global-header-table global-header-name",
            cellClass: "global-body-table global-body-name ",
          },

          {
            text: "Teléfono",
            value: "sPhone",
            class: "global-header-table global-header-phone",
            cellClass: "global-body-table",
          },
          {
            text: "Correo electrónico",
            sortable: false,
            value: "sEmail",
            class: "global-header-table global-header-email",
            cellClass: "global-body-table",
          },
          {
            text: "Ubicación﻿",
            value: "sLocation",
            class: "global-header-table global-header-location",
            cellClass: "global-body-table",
          },
          {
            text: "Acciones",
            value: "accions",
            align: "center",
            class:
              "global-header-table global-header-accions global-col-header-accion",
            cellClass:
              "global-body-table global-body-accions global-col-body-accion",
          },
        ]
        this.getPerson();
        this.searchLabel = "Buscador de proveedor tipo persona física";
        this.searchPlaceholder =
          "Ingrese nombre, correo electrónico o teléfono...";
      }

      this.$store.commit("setTabPosition", { sTabPosition: this.tab });
    },
    refreshTable: function () {
      if (this.sTabPosition === 0) {
        this.getEnterprises();
      } else {
        this.getPerson();
      }
    },
    sSearch() {
      this.iCurrentPage = 1;
      if (this.sTabPosition === 0) {
        this.getEnterprises();
      } else {
        this.getPerson();
      }
    },
    iCurrentPage: function () {
      if (this.sTabPosition === 0) {
        this.getEnterprises();
      } else {
        this.getPerson();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content-provider {
  /* background-color: coral !important; */
  padding: 0px 30px 0px 35px;
}
</style>
