var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-provider"},[_c('div',{staticClass:"content-tables-provider"},[_c('div',{staticClass:"content-global"},[_c('div',{ref:"searchbarGlobal"},[_c('header-component-global',{attrs:{"sTextTitle":"Proveedores","sTextBtnAdd":"Añadir proveedor +","bAdminPermissions":_vm.bAdminProvider,"toVieworModal":true,"setDialog":"AddProvider"}})],1),_c('div',{ref:"tabGlobal"},[_c('v-tabs',{staticClass:"global-tabs tabs-provider",attrs:{"touchless":true,"center-active":"","background-color":"transparent"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.tabsItems),function(item){return _c('v-tab',{key:item,staticClass:"global-tab"},[_vm._v(" "+_vm._s(item)+" ")])}),_c('v-tabs-slider',{staticClass:"divider-tabs"})],2)],1),_c('div',{ref:"filterGlobal"},[_c('filter-component-global',{staticClass:"global-filters",attrs:{"labelName":_vm.searchLabel,"placeholderName":_vm.searchPlaceholder},on:{"setSearch":_vm.setSearch}})],1),_c('v-tabs-items',{staticClass:"global-item-tab",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('div',{staticClass:"global-content-table",attrs:{"dark":""}},[(_vm.bEmptyTable)?_c('no-data-text-component-global',{attrs:{"iHeight":_vm.screenHeight,"sTextEmpty":_vm.sTextEmpty,"bAdminPermissions":_vm.bAdminProvider,"toVieworModal":true,"setDialog":"AddProvider"}}):_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMobile),expression:"!isMobile"}],staticClass:"global-table",attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"disable-sort":true,"items":_vm.aTable,"loading":_vm.bLoadingTable,"loading-text":"Cargando datos...","items-per-page":_vm.iItemsPerPage,"hide-default-footer":true,"no-data-text":"No hay información disponible para mostrar.","footer-props":{
                'items-per-page-text': 'Filas por página',
                'items-per-page-all-text': 'Todos',
              }},scopedSlots:_vm._u([{key:"item.accions",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{staticClass:"global-btn-table-detail",attrs:{"icon":""},on:{"click":function($event){return _vm.detailProvider(item)}}},[_c('v-icon',[_vm._v(" mdi-eye-outline ")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.bAdminProvider),expression:"bAdminProvider"}],staticClass:"global-btn-table-delete",attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v(" mdi-trash-can-outline ")])],1)]}},{key:"footer",fn:function(){return [_c('footer-table-component-global',{attrs:{"numPages":_vm.numPages,"totalItems":_vm.totalItems,"bChangeSelectedItem":_vm.bChangeSelectedItem},on:{"changeTable":_vm.getEmitEnterprises}})]},proxy:true}],null,true)},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"var(--primary-color-loading-table)","indeterminate":""}})],1)],2),_c('table-mobile-provider-component',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}],attrs:{"aTable":_vm.aTable,"headers":_vm.headers,"numPages":_vm.numPages,"totalItems":_vm.totalItems,"bChangeSelectedItem":_vm.bChangeSelectedItem},on:{"changeTable":_vm.getEmitEnterprises,"openDetailItem":_vm.detailProvider,"openDeleteItem":_vm.deleteItem}})],1)]),_c('v-tab-item',[_c('div',{staticClass:"global-content-table",attrs:{"dark":""}},[(_vm.bEmptyTable)?_c('no-data-text-component-global',{attrs:{"sTextEmpty":_vm.sTextEmpty,"iHeight":_vm.screenHeight,"bAdminPermissions":_vm.bAdminProvider,"toVieworModal":true,"setDialog":"AddProvider"}}):_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMobile),expression:"!isMobile"}],staticClass:"global-table",attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"disable-sort":true,"items":_vm.aTable,"loading":_vm.bLoadingTable,"loading-text":"Cargando datos...","items-per-page":_vm.iItemsPerPage,"hide-default-footer":true,"no-data-text":"No hay información disponible para mostrar.","footer-props":{
                'items-per-page-text': 'Filas por página',
                'items-per-page-all-text': 'Todos',
              }},scopedSlots:_vm._u([{key:"item.sEmail",fn:function(ref){
              var item = ref.item;
return [_c('span',{class:item.sEmail == 'Sin información.' ? 'text-data-empty' : ''},[_vm._v(_vm._s(item.sEmail))])]}},{key:"item.sPhone",fn:function(ref){
              var item = ref.item;
return [_c('span',{class:item.sPhone == 'Sin información.' ? 'text-data-empty' : ''},[_vm._v(_vm._s(item.sPhone))])]}},{key:"item.sLocation",fn:function(ref){
              var item = ref.item;
return [_c('span',{class:item.sLocation == 'Sin información.'
                    ? 'text-data-empty'
                    : ''},[_vm._v(_vm._s(item.sLocation))])]}},{key:"item.accions",fn:function(ref){
                    var item = ref.item;
return [_c('v-btn',{staticClass:"global-btn-table-detail",attrs:{"icon":""},on:{"click":function($event){return _vm.detailProvider(item)}}},[_c('v-icon',[_vm._v(" mdi-eye-outline ")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.bAdminProvider),expression:"bAdminProvider"}],staticClass:"global-btn-table-delete",attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v(" mdi-trash-can-outline ")])],1)]}},{key:"footer",fn:function(){return [_c('footer-table-component-global',{attrs:{"numPages":_vm.numPages,"totalItems":_vm.totalItems,"bChangeSelectedItem":_vm.bChangeSelectedItem},on:{"changeTable":_vm.gerEmitPerson}})]},proxy:true}],null,true)},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"var(--primary-color-loading-table)","indeterminate":""}})],1)],2),_c('table-mobile-person-component',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}],attrs:{"aTable":_vm.aTable,"headers":_vm.headers,"numPages":_vm.numPages,"totalItems":_vm.totalItems,"bChangeSelectedItem":_vm.bChangeSelectedItem},on:{"changeTable":_vm.gerEmitPerson,"openDetailItem":_vm.detailProvider,"openDeleteItem":_vm.deleteItem}})],1)])],1)],1)]),_c('delete-component-global',{attrs:{"oDialogDelete":_vm.oDialogDelete,"bDialogDelete":_vm.bDialogDelete},on:{"setDialogDelete":_vm.deleteItem}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }